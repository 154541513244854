.mainContainer {
  min-height: 100vh;
  position: relative;
  background: #e7e3cc;
  width: 100vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  font-family: 'Courier New' !important;
}

.sectionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: fit-content;
  width: 40vw !important;
  margin: 20px;
}

.carouselImage {
  width: 40vw !important;
  height: 40vw !important;
  cursor: pointer;
}

.infoContainer {
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}

.itemName, .itemPrice, .reproductionPrice, .outerwearPrice, .dimension {
  border-style: solid;
  border-width: 7px;
  background-color: #fff;
  border-top-color: #CBC89F;
  border-left-color: #E4E2A6;
  border-right-color: #E4E2A6;
  border-bottom-color: #F3F4D8;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 0;
}

.itemPrice, .reproductionPrice, .outerwearPrice {
  border-width: 4px;
  color: #34d85d;
}

.itemPrice {
  height: 100%;
}

.reproductionPrice {
  color: #e8001b;
}

.priceContainer {
  width: 15%;
  margin-left: 5%;
}

.itemName {
  width: 80%;
}

.dimensionsContainer {
  margin-top: 20px;
}

.detailsContainer {
  padding: 30px 0;
  display: flex;
  align-items: center;
  width: 90vw;
  justify-content: space-between;
}

.modelVideo {
  width: 25vw !important;
  margin: 0 30px;
}

.comingSoonContainer {
  padding: 20px;
  text-align: center;
  width: calc(30vw - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(30vw - 60px);
  margin: 0 60px;
  font-size: 18px;
  background-color: rgb(230, 226, 198);
}

.buttonsContainer {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.priceTag {
  background: rgb(0, 255, 124) !important;
  height: 8vw;
  padding: 5px 25px;
  background: #a84909;
  border-radius: 50%;
  text-align: center;
  font-size: 3vw;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 11px 11px 0px 0px rgba(0, 0, 0, 1);
  -moz-box-shadow: 11px 11px 0px 0px rgba(0, 0, 0, 1);
  box-shadow: 11px 11px 0px 0px rgba(0, 0, 0, 1);
}

.buyNowBtn, .buyNowBtn:hover, .buyNowBtn:active, .buyNowBtn:focus {
  padding: 5px 20px;
  height: 8vw !important;
  width: 25vw;
  border-radius: 0;
  font-size: 3vw !important;
  background: rgb(249, 255, 0) !important;
  border: none !important;
  outline: none !important;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 4px 4px 0px 0px rgba(0, 255, 247, 1), 12px 12px 0px 0px rgba(0, 0, 0, 1);
  -moz-box-shadow: 4px 4px 0px 0px rgba(0, 255, 247, 1), 12px 12px 0px 0px rgba(0, 0, 0, 1);
  box-shadow: 4px 4px 0px 0px rgba(0, 255, 247, 1), 12px 12px 0px 0px rgba(0, 0, 0, 1);
}

.priceTag p, .buyNowBtn p {
  border-bottom: 3px solid #000;
  padding: 0;
  margin: 0;
  line-height: 35px;
}

.buyNowBtn p {
  line-height: 45px;
  width: fit-content;
}

.reproductionBtn {
  text-align: center;
  border: none !important;
  outline: none !important;
  background: none;
  position: relative;
  right: -7vw;
  top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 25vw;
  padding: 20px;
}

.reproductionBtn img {
  position: absolute;
  left: -20px;
  right: 0;
  top: -5px;
  margin: auto;
  width: 25vw;
  z-index: 1;
}

.buyReproduction {
  z-index: 2;
  padding: 20px 20px 0 20px;
  font-size: 2vw;
  cursor: pointer;
}

.reproductionInfo {
  background-color: rgb(255, 0, 0);
  padding: 5px 20px;
  z-index: 2;
  text-decoration: underline;
  cursor: pointer;
}

.zoomImageContainer {
  height: 100vh;
  width: 100vw;
  background-color: rgb(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.zoomImageContainer img {
  max-width: 90%;
  max-height: 90%;
}

.zoomImageContainer button {
  position: absolute;
  right: 30px;
  top: 10px;
  border-radius: 50%;
  background: none;
  outline: none !important;
  border: none !important;
  color: #fff;
  font-weight: bold;
  font-size: 24px;
  z-index: 3;
}

.descriptionContainer {
  margin-top: 30px;
  width: 80vw;
}

.dimensionsContainer {
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dimensionsContent {
  display: table;
  width: 45vw;
  min-height: 50px;
  height: 100%;
}

.dimension {
  position: relative;
  align-items: center;
  padding-left: 40px;
  width: 50%;
  font-size: 14px;
  display: table-cell;
  min-height: 50px;
}

.dimension img {
  height: 30px;
  width: 30px;
  position: absolute;
  left: 5px;
}

.dimension p {
  margin: 0;
  padding-top: 5px;
}

.headbandDimension {
  width: 100% !important;
}

@media (max-width: 768px) {
  .comingSoonContainer {
    width: 60vw;
    height: 60vw;
    margin-bottom: 30px;
  }

  .wornImagesCarousel {
    margin-top: 30px;
  }

  .carouselImage {
    width: 60vw !important;
    height: 60vw !important;
  }

  .sectionContainer {
    width: 70vw !important;
  }

  .modelVideo {
    width: 60vw !important;
  }

  .detailsContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .buttonsContainer {
    margin-top: 30px;
  }

  .zoomImageContainer button {
    right: 20px;
  }

  .descriptionContainer {
    text-align: center;
  }

  .priceTag, .buyNowBtn {
    height: 80px;
    font-size: 30px;
  }

  .reproductionBtn {
    right: 0;
    margin-bottom: 50px;
  }

  .buyReproduction {
    padding: 10px 10px 0 0px;
  }

  .dimensionsContainer {
    margin-top: 10px;
  }

  .dimensionsContent {
    width: 60vw;
  }

  .dimension {
    display: flex;
    width: 100% !important;
    margin-top: 6px;
  }

  .dimension img {
    left: 10px;
  }
}

@media (max-width: 576px) {
  .priceTag, .buyNowBtn {
    height: 80px;
    font-size: 20px !important;
  }

  .buyNowBtn, .buyNowBtn:hover {
    width: fit-content;
    height: fit-content !important;
  }

  .reproductionBtn {
    right: 0;
    margin-bottom: 100px;
  }

  .buyReproduction {
    padding: 10px 10px 0 0px;
    font-size: 16px;
  }

  .reproductionBtn img {
    left: -15vw;
    width: 55vw;
  }

  .priceContainer {
    width: 20%;
  }
}
