.mainContainer {
  min-height: 100vh;
  position: relative;
  background: linear-gradient(rgb(231, 227, 205), rgb(234, 232, 207));
  width: 100vw;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
}

.clothesVideo, .categoryVideo {
  position: absolute;
  top: 0 !important;
  width: 100vw !important;
  margin-top: -5px;
}

.textVideoContainer {
  position: absolute;
  left: 0; 
  right: 0;
  top: 30px;
  margin: auto;
  width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.textVideo {
  width: 35vw !important;
  height: fit-content !important;
  -webkit-animation: fadeIn 2s;
  -moz-animation: fadeIn 2s;
  -o-animation: fadeIn 2s;
  animation: fadeIn 2s;
}

@keyframes fadeIn {
  from {opacity: 0}

  to {opacity: 1}
}

@-moz-keyframes fadeIn {
  from {opacity: 0}

  to {opacity: 1}
}

@-webkit-keyframes fadeIn {
  from {opacity: 0}

  to {opacity: 1}
}

@-o-keyframes fadeIn {
  from {opacity: 0}

  to {opacity: 1}
}

.linksContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-delay: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  margin-top: 30px;
}

.link {
  margin: 5px 25px;
  color: #000;
  font-family: 'Courier New';
  text-decoration: underline !important;
  font-size: 20px;
  outline: none !important;
  border: none !important;
  background: transparent;
}

.link:hover {
  font-weight: 600;
  color: #000;
  text-decoration: none;
}


@media (max-width: 900px) {
  .mainContainer {
    background: linear-gradient(rgb(233, 230, 208), rgb(234, 232, 207));
  }

  .clothesVideo, .categoryVideo {
    height: 100vh !important;
    position: absolute;
    margin-top: -5px;
    top: 0 !important;
  }
}

@media (max-width: 768px) {
  .textVideo {
    width: 45vw !important;
  }
}

@media (max-width: 576px) {
  .link {
    font-size: 16px;
    margin: 2px 15px;
  }
}
