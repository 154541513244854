.itemContainer {
  width: 45vw;
  text-align: center;
  margin: 10px 0;
  font-family: 'Courier New';
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.itemImage, .itemVideo, .headbandImage, .headbandVideo {
  opacity: 1;
  animation: fadeIn 0.5s linear;
}

.itemImage, .itemVideo {
  height: 27vw !important;
}

.headbandImage, .headbandVideo {
  height: 15vw !important;
}

@keyframes fadeIn {
  0% {opacity: 0}
  100% {opacity: 1}
}

.itemVideo {
  width: 47vw !important;
}

.itemName, .itemPrice {
  margin-bottom: 0;
}

.infoContainer {
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 90%;
  position: relative;
  margin-top: 20px;
  cursor: pointer;
}

.itemName, .itemPrice, .reproductionPrice, .outerwearPrice {
  border-style: solid;
  border-width: 7px;
  background-color: #fff;
  border-top-color: #CBC89F;
  border-left-color: #E4E2A6;
  border-right-color: #E4E2A6;
  border-bottom-color: #F3F4D8;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 0;
}

.itemPrice, .reproductionPrice, .outerwearPrice {
  border-width: 4px;
  color: #34d85d;
}

.itemPrice {
  height: 100%;
}

.reproductionPrice {
  color: #e8001b;
}

.priceContainer {
  width: 15%;
  margin-left: 5%;
}

.itemName {
  width: 80%;
}

@media (max-width: 900px) {
  .itemContainer {
    width: 90vw !important;
  }

  .itemImage, .itemVideo {
    height: 37vw !important;
  }

  .itemVideo {
    width: 52vw !important;
  }

  .headbandImage, .headbandVideo {
    height: 20vw !important;
  }
}

@media (max-width: 576px) {
  .itemName, .reproductionPrice, .outerwearPrice, .itemPrice {
    font-size: 14px;
  }

  .priceContainer {
    width: 20%;
    margin-left: 2%;
  }

  .headbandImage, .headbandVideo {
    height: 25vw !important;
  }
}
