.navbar {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #e7e3cc;
  padding: 20px;
}

.textVideo {
  width: 35vw !important;
  height: fit-content !important;
  cursor: pointer;
}

.linksContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.link {
  margin: 5px 25px;
  color: #000;
  font-family: 'Courier New';
  text-decoration: underline !important;
  font-size: 20px;
  outline: none !important;
  border: none !important;
  background: transparent;
}

.link:hover {
  font-weight: 600;
  color: #000;
  text-decoration: none;
}

@media (max-width: 768px) {
  .textVideo {
    width: 45vw !important;
  }
}
