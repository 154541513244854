.mainContainer {
  min-height: 100vh;
  position: relative;
  background: #e7e3cc;
  width: 100vw;
  overflow-x: hidden !important;
  display: flex;
  justify-content: center;
}

.gridContainer {
  opacity: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin-top: -30px !important;
}


@media (max-width: 900px) {
  .gridContainer {
    justify-content: center;
    margin-top: 0px !important;
  }
}
